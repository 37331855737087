import { News, NewsItemActionCreate, NewsService } from '@common/clients/api';
import { ApiService } from '@common/clients/request/ApiService';
import { ContextData } from '@common/defaults';
import { UserContext } from '@common/defaults/UserContext';

export async function createNews({
    requestBody,
    contextData,
    userContext,
}: {
    requestBody: NewsItemActionCreate;
    contextData: ContextData;
    userContext: UserContext;
}): Promise<News> {
    return ApiService<NewsService>({
        contextData,
        userContext,
        isClientSide: true,
        service: NewsService,
    }).createNewsByDomainId({
        contextId: contextData.context.id,
        requestBody,
    });
}
