import { useState } from 'react';

import { Button, ButtonType } from '@admin/atoms/Button';
import { LoadingButton } from '@admin/atoms/LoadingButton';
import {
    BaseModal,
    BaseModalFooter,
    BaseModalHeader,
    BaseModalType,
    useModalContext,
} from '@admin/organisms/BaseModal';
import { Form } from '@admin/organisms/Form';
import { FormFields } from '@admin/types';
import FlashMessageUtil from '@admin/utils/FlashMessageUtil/FlashMessageUtil';
import { TranslationKey } from '@admin/utils/TranslationKey';
import { useTranslation } from '@cms/i18n';
import { CancelablePromise } from '@common/clients/api';

export interface Props<Entity, CreateEntity> {
    formFields: FormFields;
    onSuccess: () => void;
    submit: (data: CreateEntity) => CancelablePromise<Entity> | Promise<Entity> | Promise<void>;
    __translationKey: TranslationKey | string;
}

export function CreateModal<Entity, CreateEntity>({
    formFields,
    onSuccess,
    submit,
    __translationKey,
}: Props<Entity, CreateEntity>) {
    const __translate = useTranslation(__translationKey).t;
    const flashes = FlashMessageUtil(__translate);

    const { closeModal } = useModalContext(BaseModalType.Create);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const handleSubmit = (data: CreateEntity) =>
        submit(data)
            .then(closeModal)
            .then(onSuccess)
            .then(flashes.createSuccess)
            .catch(flashes.createError)
            .finally(() => setIsSubmitting(false));

    const clickButton = () => {
        setIsSubmitting(true);
        doSubmit();
    };

    let doSubmit: () => void;
    const bindDoSubmit = (callback: () => void) => {
        doSubmit = callback;
    };

    return (
        <BaseModal contextKey={BaseModalType.Create}>
            <BaseModalHeader contextKey={BaseModalType.Create}>
                {__translate('createModal.title')}
            </BaseModalHeader>
            <Form<Entity, CreateEntity>
                id={'create'}
                action={'create'}
                handleSubmit={handleSubmit}
                bindDoSubmit={bindDoSubmit}
                formFields={formFields}
                onDataInvalid={() => setIsSubmitting(false)}
            />
            <BaseModalFooter className={'flex justify-between'}>
                {isSubmitting ? (
                    <LoadingButton className={'w-full'} filled={true}></LoadingButton>
                ) : (
                    <Button filled={true} className={'w-full'} type={ButtonType.submit} onClick={clickButton}>
                        {__translate('new')}
                    </Button>
                )}
            </BaseModalFooter>
        </BaseModal>
    );
}
